// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from './type';

export const environment: Environment = {
  production: false,
  clientId: 'tagea-cc',
  oidcConfigURL:
    'https://keycloak.staging.tremaze.de/auth/realms/tremaze-staging/.well-known/openid-configuration',
  basePath: 'https://api.staging.tagea.app/',
  enableServiceWorker: true,
  lightThemeLogoPath: null,
  darkThemeLogoPath: null,
  iconPath: null,
  productName: null,
  disableFreshChat: false,
  stage: 'PROD',
  enableVitaAdvisorFor: '265bd4cb-eeac-4703-8c14-1ebf64b1c032',
};
